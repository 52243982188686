export const queryKeys = {
  categories: 'categories',
  images: 'images',
  markets: 'markets',
  product: 'product',
  products: 'products',
  search: 'search',
  searches: 'searches',
  store: 'store',
  stores: 'stores',
  storesByCategory: 'storesByCategory',
  user: 'user',
};
